module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-preact@7.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13__jhlzboqprl74fqzesatsaks5ci/node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.1_aabc26h2s44nploegiz55dfwra/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kevin Pennarun","short_name":"Agado Dev","start_url":"/","background_color":"#4267b2","theme_color":"#4267b2","display":"minimal-ui","icon":"src/images/agado-logo/agado-logo_without_text.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3bc161d173ed4a90e2fa8a7562bf7dda"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.13.3_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_ecje56owlj3x43b2zplb56aeqy/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._b5a5pik4m3wlnzrtyb5hbzvdqu/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
